<template>
  <n-card>
    <n-space vertical="true">
      <n-radio-group v-model:value="new_type" name="radiobutton-group">
        <n-radio-button
            v-for="type in type_list"
            :key="type"
            :value="type"
        >
          {{ type }}
        </n-radio-button>
      </n-radio-group>
      <n-input
          v-model="new_url"
      ></n-input>
      <n-button
          @click="add_config"
          type="primary"
      >
        Add
      </n-button>
    </n-space>
  </n-card>
  <br/>
  <n-data-table
      :columns="columns"
      :data="getProvider"
      :loading="loading"
  ></n-data-table>
</template>

<script>
import axios from "axios";
import {NButton} from "naive-ui";
import {h} from "vue";

export default {
  name: "ProviderView",
  data() {
    return {
      loading: true,
      type_list: ['clash', 'SIP008', 'SIP003'],
      new_type: "",
      new_url: "",
      provider_list: [],
    };
  },
  methods: {
    getProvider: async function () {
      const res = await axios.get('/api/get_artifact_list')
      if (res.status !== 200) {
        console.log(res.statusText);
        return;
      }
      let body = JSON.parse(res.data);
      this.loading = false;
      this.provider_list = body;
      return body
    },
    del_config: async function (config) {
      let body = {
        type: 'delete',
        config: config
      }
      const res = await axios.post('/api/set_artifact', body)
      if (res.status !== 200) {
        console.log(res.data);
      }
    },
    columns: async function () {
      return [
        {
          title: "Type",
          key: "type"
        },
        {
          title: "URL",
          key: "url"
        },
        {
          title: "Action",
          key: "actions",
          render(row) {
            return h(NButton, {
              strong: true,
              tertiary: true,
              size: "small",
              onClick: () => this.del_config(row)
            }, {default: () => "delete"});
          }
        }
      ];
    },
    add_config: async function () {
      if (this.new_url === "") {
        return;
      }
      let body = {
        type: 'write',
        config: {
          type: this.type,
          url: this.url
        }
      }
      const res = await axios.post('/api/set_artifact', body)
      if (res.status !== 200) {
        console.log(res.data);
      }
      this.new_url = "";
    }
  },
}
</script>

<style scoped>

</style>
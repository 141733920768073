<template>
<p>test</p>
</template>

<script>
export default {
  name: "ArtifactView"
}
</script>

<style scoped>

</style>
import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ArtifactView from '../views/ArtifactView.vue'
import ProviderView from '../views/ProviderView'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      requireAuth: true,
    }
  },
  {
    path: '/artifact',
    name: 'Artifact',
    component: ArtifactView,
    meta: {
      requireAuth: true,
    }
  },
  {
    path: '/provider',
    name: 'Provider',
    component: ProviderView,
    meta: {
      requireAuth: true,
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/LoginView')
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})



export default router
